<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            6. Автоматическая установка пожаротушения
          </v-card-title>
          <v-card-text class="title font-weight-light">
            <v-card
              class="pa-2 d-flex justify-center align-center"
              full-width
              flat

              >
                <v-img
                  lazy-src="img/material/sprinkler.svg"
                  max-width="100"
                  src="img/material/sprinkler.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  <p><span class="red--text font-weight-bold">Автоматическая установка пожаротушения (АУП)  </span>- установка, автоматически срабатывающая при превышении контролируемым фактором пожара установленных пороговых значений в защищаемой зоне, а также обеспечивающая передачу сигнала о пожаре во внешние цепи.</p>
                </v-card-text>
              </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-container>
              <v-row>
                <v-col cols="7">
                  <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/fire-alarm-2.svg"
                      max-width="100"
                      src="img/material/fire-alarm-2.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      АУП должны выполнять функцию автоматической пожарной сигнализации от собственных технических средств или средств в составе СПС, в соответствии с требованиями НД в области ПБ
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="5">
                  <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/document-shield.svg"
                      max-width="100"
                      src="img/material/document-shield.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      На АУП разрабатывается проектная и (или) рабочая документация
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="7">
                  <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/power-plant.svg"
                      max-width="100"
                      src="img/material/power-plant.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      АУП проектируют с учетом архитектурных, конструктивных и объемно-планировочных решений зданий и размещаемого в них оборудования, возможности и условий применения огнетушащих веществ
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="5">
                  <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/fire-shield.svg"
                      max-width="100"
                      src="img/material/fire-shield.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      Установки предназначены для тушения пожаров классов А и В (по ГОСТ 27331) и класса Е
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center">

                <v-dialog
                  v-model="dialog1"
                  width="50%"
                  class="pa-16"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      height="50"
                      color="red"
                      class="mr-3"
                      v-on="on"
                      v-bind="attrs"
                      >
                        Требования к автоматическим и автономным установкам пожаротушения
                    </v-btn>
                  </template>
                  <v-card>
                    <Modal1></Modal1>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        text
                        @click="dialog1 = false"
                      >
                        Закрыть
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialog2"
                  width="90%"
                  class="pa-16"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      height="50"
                      color="red"
                      class="mr-3"
                      v-on="on"
                      v-bind="attrs"
                      outlined
                      >
                        Классификация установок пожаротушения
                    </v-btn>
                  </template>
                  <v-card>
                    <Modal2></Modal2>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        text
                        @click="dialog2 = false"
                      >
                        Закрыть
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </div>
          </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
import Modal1 from '@/components/Page33/Modal1.vue'
import Modal2 from '@/components/Page33/Modal2.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null
  }),
  components: {
    Hint,
    Modal1,
    Modal2
  }
}
</script>
